'use client'

import React, { ReactElement } from 'react'

export default function NotFoundPage(): ReactElement {
  function resizeIframe(): void {
    const iframe = document.getElementById('content') as HTMLIFrameElement
    iframe.style.height = iframe.contentWindow!.document.body.scrollHeight.toString() + 'px'
  }

  function changeLinksTarget(): void {
    const iframe = document.getElementById('content') as HTMLIFrameElement
    const links = iframe.contentDocument!.links // Get all links inside the iframe
    for (let i = 0; i < links.length; i++) {
      if (links[i].target === '_blank') continue // Skip links with target="_blank" (or any other target
      links[i].target = '_top' // Change the target attribute of each link to '_top'
    }
  }

  function updateTitleAndMetaDescription(): void {
    // Access the iframe using its id
    const iframe = document.getElementById('content') as HTMLIFrameElement
    if (!iframe) {
      console.error("Iframe with id 'content' not found")
      return
    }

    // Access the contentDocument of the iframe
    const doc = iframe.contentDocument
    if (!doc) {
      console.error('Unable to access the contentDocument of the iframe')
      return
    }

    // Extract title from the iframe's DOM
    const title = doc.head.querySelector('title')
    if (title) {
      document.title = title.textContent || ''
    }

    // Extract meta description from the iframe's DOM
    const metaDescription: HTMLMetaElement | null = doc.head.querySelector(
      'meta[name="description"]',
    )
    if (metaDescription) {
      // Update or create meta description tag in the current page
      const currentMetaDescription = document.head.querySelector(
        'meta[name="description"]',
      ) as HTMLMetaElement
      if (currentMetaDescription) {
        currentMetaDescription.content = metaDescription.content
      } else {
        const newMetaDescription = document.createElement('meta')
        newMetaDescription.name = 'description'
        newMetaDescription.content = metaDescription.content
        document.head.appendChild(newMetaDescription)
      }
    }
  }

  function afterLoad(): void {
    resizeIframe()
    changeLinksTarget()
    updateTitleAndMetaDescription()
  }

  return (
    <html>
      <body>
        <iframe
          src="/not-found/"
          id="content"
          style={{ width: '100vw' }}
          onLoad={() => afterLoad()}
        />
      </body>
    </html>
  )
}
